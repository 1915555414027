import React, { Component } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';

import { NamedLink } from '../../../components';
import {users, usersWithImages, usersWithImagesMobile} from './custom_users';

import css from './SectionFilteredSearches.module.css';
import { first } from 'lodash';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.


// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Create a "card" that contains a link to filtered search on SearchPage.
const FilterLink = props => {
  const { name, image, link, className } = props;
  const url = typeof window !== 'undefined' ? new window.URL(link) : new global.URL(link);
  const searchQuery = url.search;
  const nameText = <span className={css.searchName}>{name}</span>;
  const classed = className? `${className}` : null;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.searchLink + " " + classed}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <img src={image} alt={name} className={css.searchImage} />
        </div>
      </div>
    </NamedLink>
  );
};

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionFilteredSearches = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  return (
    <div className={css.landingPageSections}>
      <div className={css.TopUserNameList}>
        {users.map((user, index) => {
          if(index < users.length/2)
          {
            return (
              <NamedLink 
                key={index}
                className={`${css.profileLink}`}
                name="ProfilePage"
                params={{ id: user.username }}
              >
                <span className={css.profileName}>{user.username}</span>
              </NamedLink>  
            );
          }
        })}
      </div>
      
      <div className={css.filteredSearches}>
        <div className={css.firstCol} id={"firstCol"}>
          {
            usersWithImages.map((user, index) => {              
              if(index < 5)
              {
                return (
                  <NamedLink 
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifOneFifty}
                    />                    
                  </NamedLink>
                );
              }
            }
          )}
        </div>
        <div className={css.secondCol} id={"secondCol"}>
          {
            usersWithImages.map((user, index) => {
              if(index >= 5 && index < 10)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifHundred}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>
        <div className={css.secondColMobile} id={"secondCol"}>
          {
            usersWithImagesMobile.map((user, index) => {
              if(index >= 5 && index < 11)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifHundred}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>
        <div className={css.thirdCol} id={"thirdCol"}>
          {
            usersWithImages.map((user, index) => {
              if(index >= 10 && index < 15)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifOneFifty}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>
        
        <div className={css.fourthCol} id={"fourthCol"}>
          {
            usersWithImages.map((user, index) => {
              if(index >= 15 && index < 19)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifHundred}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>

        <div className={css.fourthColMobile} id={"fourthCol"}>
          {
            usersWithImagesMobile.map((user, index) => {
              if(index >= 16 && index < 22)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifHundred}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>

        <div className={css.fifthCol} id={"fifthCol"}>
          {
            usersWithImages.map((user, index) => {
              if(index >= 19 && index < usersWithImages.length)
              {
                return (
                  <NamedLink
                  key={index}
                  className={`${css.profileLink}`}
                  name="ProfilePage"
                  params={{ id: user.username }}
                  >
                    <img
                      src={user.image}
                      alt={user.username}
                      className={css.gifOneFifty}
                    />
                  </NamedLink>
                );
              }
            }
          )}
        </div>
      </div>
      <div className={css.ButtomUserNameList}>
        {users.map((user, index) => {
          if(index >= users.length/2)
          {
            return (
              <NamedLink 
                key={index}
                className={`${css.profileLink}`}
                name="ProfilePage"
                params={{ id: user.username }}
              >
                <span className={css.profileName}>{user.username}</span>
              </NamedLink>  
            );
          }
        })}
      </div>
    </div>
  );
};

SectionFilteredSearches.defaultProps = { rootClassName: null, className: null };

SectionFilteredSearches.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionFilteredSearches;