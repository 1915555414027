import CHIAYUNHU from './images/CHIAYUNHU.jpg';
import CHLOEMARIO from './images/CHLOEMARIO.jpg';
import DESIREESCARBOROUGH from './images/DESIREESCARBOROUGH.jpg';
import ELLIANACAPRI from './images/ELLIANACAPRI.png';
import FLORAGILD from './images/FLORAGILD.jpg';
import GINAGNRW1 from './images/GINAGNRW1.jpg';
import GINAGNRW3 from './images/GINAGNRW3.jpg';
import HEINRICHZHANG from './images/HEINRICHZHANG.jpg';
import JESSICAMATIER from './images/JESSICAMATIER.jpg';
import JIAYINGCHEN from './images/JIAYINGCHEN.jpg';
import MANMADESKINS from './images/MANMADESKINS.jpg';
import MATILDASUNDKLER from './images/MATILDASUNDKLER.jpg';
import MICHAELLANDIVAR from './images/MICHAELLANDIVAR.jpg';
import PARKERBELLDEVANEY from './images/PARKERBELLDEVANEY.jpg';
import PUERPARASITUS1 from './images/PUERPARASITUS1.jpg';
import PUERPARASITUS2 from './images/PUERPARASITUS2.jpg';
import SGUSCIO from './images/SGUSCIO.jpg';
import SKNDLSS1 from './images/SKNDLSS1.jpg';
import SKNDLSS2 from './images/SKNDLSS2.jpg';
import SOLHANSDOTTIR from './images/SOLHANSDOTTIR.png';
import SONGLANWU from './images/SONGLANWU.jpg';
import RHYCNI from './images/RHYCNI.gif';
import ZAYDLATIB1 from './images/ZAYDLATIB1.jpg';
import ZAYDLATIB2 from './images/ZAYDLATIB2.jpg';
import GINA from './images/gina.jpg';
import EMBRYO_A_03_1 from './images/EMBRYO_A_03_1.jpg';
import VICTORIA from './images/VICTORIA.gif';
import SORCERER from './images/SORCERER.gif';
import XUZHANG from './images/XUZHANG.gif';
import XUZHANG1 from './images/XUZHANG1.jpg';
import FRANCISCOFELIX from './images/FRANCISCOFELIX.jpg';
import ZAYD from './images/ZAYD.gif';
import TAREET from './images/TAREET.gif';
import ANNACOMPAGNONE from './images/ANNACOMPAGNONE.jpg';
import SARAHGARFIELD from './images/SARAHGARFIELD.gif'

export const users = [
    {
      "email": "puerparasitus@yahoo.com",
      "firstName": "Puer",
      "lastName": "Parasitus",
      "username": "PuerParasitus",
      "password": "password",
      "image": "PUERPARASITUS1",
      "userId": "626d10fc-1475-4690-a434-bb18ea5a142a"
    },
    {
      "email": "franciscofelixonline@gmail.com",
      "firstName": "Francisco",
      "lastName": "FÉLIX",
      "username": "FRANCISCO-FÉLIX",
      "password": "password",
      "image": "FRANCISCOFELIX",
      "userId": "6387a97a-9bbb-4533-b828-e41d3e04f9ea"
    },
    {
      "email": "generalvi.world@gmail.com",
      "firstName": "Victoria",
      "lastName": "Generalova",
      "username": "generalvi",
      "password": "password",
      "image": "VICTORIA",
      "userId": "647d856f-5016-423c-bd38-57cd2dc4d31b"
    },
    {
      "email": "agarflowers@gmail.com",
      "firstName": "Yi Hsuan",
      "lastName": "Sung",
      "username": "FLORAGILD",
      "password": "password",
      "image": FLORAGILD,
      "userId": "626d13e5-e6a9-4689-9933-49ab2a490bae"
    },
    {
      "email": "contactdesireescarborough@gmail.com",
      "firstName": "Desiree",
      "lastName": "Scarborough",
      "username": "DesireeScarborough",
      "password": "password",
      "image": "DESIREESCARBOROUGH",
      "userId": "626d143a-8b90-449c-b9f1-cf2a14de693e"
    },
    {
      "email": "chenjiaying6688@gmail.com",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "JiayingChen",
      "password": "password",
      "image": "JIAYINGCHEN",
      "userId": "627e9328-f8e4-4eaa-a67d-247ffd3c2dea"
    },
    {
      "email": "studio.sarahgarfield@gmail.com",
      "firstName": "Sarah",
      "lastName": "Garfield",
      "username": "Sarah Garfield_London",
      "password": "password",
      "image": "SARAHGARFIELD",
      "userId": "62a0c3bd-6320-4494-a17b-7376cd73050a"
    },
    {
      "email": "ediop@ifm-paris.fr",
      "firstName": "Tareet",
      "lastName": "_",
      "username": "tareet",
      "password": "password",
      "image": "TAREET",
      "userId": "6351ea6c-2024-4441-b63a-a50247e5d118"
    },
    {
      "email": "sorcerersourcing@gmail.com",
      "firstName": "sorcerer",
      "lastName": "_",
      "username": "sorcerer",
      "password": "password",
      "image": "SORCERER",
      "userId": "62894662-8971-4249-bddd-739f6b134598"
    },
    {
      "email": "studio@ginagrnw.com",
      "firstName": "Gina",
      "lastName": "Grnw",
      "username": "GinaGrnw",
      "password": "password",
      "image": "GINAGNRW1",
      "userId": "626d1628-1e77-4fb6-b31c-4055a5b6ae8f"
    },
    {
      "email": "xu@studiobay.co.uk",
      "firstName": "XU",
      "lastName": "ZHANG",
      "username": "XUZHANG",
      "password": "password",
      "image": "XUZHANG1",
      "userId": "62c9931a-301b-4848-959c-eb167c390b12"
    },
    {
      "email": "xu@studiobay.co.uk",
      "firstName": "XU",
      "lastName": "ZHANG",
      "username": "XUZHANG",
      "password": "password",
      "image": "XUZHANG",
      "userId": "62c9931a-301b-4848-959c-eb167c390b12"
    },
    {
      "email": "yuxuanzhang@fitnyc.edu",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "HeinrichZhang",
      "password": "password",
      "image": "HEINRICHZHANG",
      "userId": "626d16da-9dc0-4888-ac58-d6d229abe206"
    },
    {
      "email": "info@skndlss.xyz",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "SKNDLSS",
      "password": "password",
      "image": "SKNDLSS1",
      "userId": "626d1709-000d-42e4-907e-d6c729a45f7b"
    },
    {
      "email": "info@manmadeskins.com",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "ManMadeSkins",
      "password": "password",
      "image": "MANMADESKINS",
      "userId": "626d172f-7e7a-48ad-b647-ae1a9ef75956"
    },
    {
      "email": "jessicammatier@gmail.com",
      "firstName": "Jessica",
      "lastName": "Matier",
      "username": "JessicaMatier",
      "password": "password",
      "image": "JESSICAMATIER",
      "userId": "626d175a-f97e-46a1-8490-14f16a21b8ea"
    },
    {
      "email": "michaelravidnal@gmail.com",
      "firstName": "Michael",
      "lastName": "Landivar",
      "username": "MichaelLandivar",
      "password": "password",
      "image": "MICHAELLANDIVAR",
      "userId": "626d1798-e390-452c-92eb-4324af221cfe"
    },
    {
      "email": "zaydlatib@gmail.com",
      "firstName": "Zayd",
      "lastName": "Latib",
      "username": "JawlNini",
      "password": "password",
      "image": "ZAYD",
      "userId": "626d14f3-8419-45a8-bd08-a5a67429d86b"
    },
    {
      "email": "rhycni@gmail.com",
      "firstName": "RHYCNI",
      "lastName": "RHYCNI",
      "username": "RHYCNI",
      "password": "password",
      "image": RHYCNI,
      "userId": "62efd4b9-b196-4ec7-ab38-dac2dd1df02e"
    },
    {
      "email": "annacompagnone@icloud.com",
      "firstName": "Anna",
      "lastName": "Compagnone",
      "username": "Annacompagnone",
      "password": "password",
      "image": ANNACOMPAGNONE,
      "userId": "62da1275-4fd2-44a7-829b-ad314a6d4465"
    }
    
]

export const usersWithImages = [
  {
    "email": "info@manmadeskins.com",
    "firstName": "Sample",
    "lastName": "Name",
    "username": "ManMadeSkins",
    "password": "password",
    "image": MANMADESKINS,
    "userId": "626d172f-7e7a-48ad-b647-ae1a9ef75956"
  },
  {
    "email": "chenjiaying6688@gmail.com",
    "firstName": "Sample",
    "lastName": "Name",
    "username": "JiayingChen",
    "password": "password",
    "image": JIAYINGCHEN,
    "userId": "627e9328-f8e4-4eaa-a67d-247ffd3c2dea"
  },
  {
    "email": "contactdesireescarborough@gmail.com",
    "firstName": "Desiree",
    "lastName": "Scarborough",
    "username": "DesireeScarborough",
    "password": "password",
    "image": DESIREESCARBOROUGH,
    "userId": "626d143a-8b90-449c-b9f1-cf2a14de693e"
  },
  {
    "email": "zaydlatib@gmail.com",
    "firstName": "Zayd",
    "lastName": "Latib",
    "username": "JawlNini",
    "password": "password",
    "image": ZAYD,
    "userId": "626d14f3-8419-45a8-bd08-a5a67429d86b"
  },
  {
    "email": "sorcerersourcing@gmail.com",
    "firstName": "sorcerer",
    "lastName": "_",
    "username": "sorcerer",
    "password": "password",
    "image": SORCERER,
    "userId": "62894662-8971-4249-bddd-739f6b134598"
  },
  {
    "email": "xu@studiobay.co.uk",
    "firstName": "XU",
    "lastName": "ZHANG",
    "username": "XUZHANG",
    "password": "password",
    "image": XUZHANG1,
    "userId": "62c9931a-301b-4848-959c-eb167c390b12"
  },
  {
    "email": "studio@ginagrnw.com",
    "firstName": "Gina",
    "lastName": "Grnw",
    "username": "GinaGrnw",
    "password": "password",
    "image": GINAGNRW1,
    "userId": "626d1628-1e77-4fb6-b31c-4055a5b6ae8f"
  },
  {
    "email": "ediop@ifm-paris.fr",
    "firstName": "Tareet",
    "lastName": "_",
    "username": "tareet",
    "password": "password",
    "image": TAREET,
    "userId": "6351ea6c-2024-4441-b63a-a50247e5d118"
  },
  {
    "email": "info@skndlss.xyz",
    "firstName": "Sample",
    "lastName": "Name",
    "username": "SKNDLSS",
    "password": "password",
    "image": SKNDLSS2,
    "userId": "626d1709-000d-42e4-907e-d6c729a45f7b"
  },
  {
    "email": "annacompagnone@icloud.com",
    "firstName": "Anna",
    "lastName": "Compagnone",
    "username": "Annacompagnone",
    "password": "password",
    "image": ANNACOMPAGNONE,
    "userId": "62da1275-4fd2-44a7-829b-ad314a6d4465"
  },
  {
    "email": "info@skndlss.xyz",
    "firstName": "Sample",
    "lastName": "Name",
    "username": "SKNDLSS",
    "password": "password",
    "image": SKNDLSS1,
    "userId": "626d1709-000d-42e4-907e-d6c729a45f7b"
  },
  {
    "email": "puerparasitus@yahoo.com",
    "firstName": "Puer",
    "lastName": "Parasitus",
    "username": "PuerParasitus",
    "password": "password",
    "image": PUERPARASITUS1,
    "userId": "626d10fc-1475-4690-a434-bb18ea5a142a"
  },
  {
    "email": "studio.sarahgarfield@gmail.com",
    "firstName": "Sarah",
    "lastName": "Garfield",
    "username": "Sarah Garfield_London",
    "password": "password",
    "image": SARAHGARFIELD,
    "userId": "62a0c3bd-6320-4494-a17b-7376cd73050a"
  },
  {
    "email": "rhycni@gmail.com",
    "firstName": "RHYCNI",
    "lastName": "RHYCNI",
    "username": "RHYCNI",
    "password": "password",
    "image": RHYCNI,
    "userId": "62efd4b9-b196-4ec7-ab38-dac2dd1df02e"
  },
  {
    "email": "xu@studiobay.co.uk",
    "firstName": "XU",
    "lastName": "ZHANG",
    "username": "XUZHANG",
    "password": "password",
    "image": XUZHANG,
    "userId": "62c9931a-301b-4848-959c-eb167c390b12"
  },
  {
    "email": "generalvi.world@gmail.com",
    "firstName": "Victoria",
    "lastName": "Generalova",
    "username": "generalvi",
    "password": "password",
    "image": VICTORIA,
    "userId": "647d856f-5016-423c-bd38-57cd2dc4d31b"
  },
  {
    "email": "zaydlatib@gmail.com",
    "firstName": "Zayd",
    "lastName": "Latib",
    "username": "JawlNini",
    "password": "password",
    "image": ZAYDLATIB2,
    "userId": "626d14f3-8419-45a8-bd08-a5a67429d86b"
  },
  {
    "email": "puerparasitus@yahoo.com",
    "firstName": "Puer",
    "lastName": "Parasitus",
    "username": "PuerParasitus",
    "password": "password",
    "image": PUERPARASITUS2,
    "userId": "626d10fc-1475-4690-a434-bb18ea5a142a"
  },
  {
    "email": "jessicammatier@gmail.com",
    "firstName": "Jessica",
    "lastName": "Matier",
    "username": "JessicaMatier",
    "password": "password",
    "image": JESSICAMATIER,
    "userId": "626d175a-f97e-46a1-8490-14f16a21b8ea"
  },
  {
    "email": "yuxuanzhang@fitnyc.edu",
    "firstName": "Sample",
    "lastName": "Name",
    "username": "HeinrichZhang",
    "password": "password",
    "image": HEINRICHZHANG,
    "userId": "626d16da-9dc0-4888-ac58-d6d229abe206"
  },
  {
    "email": "michaelravidnal@gmail.com",
    "firstName": "Michael",
    "lastName": "Landivar",
    "username": "MichaelLandivar",
    "password": "password",
    "image": MICHAELLANDIVAR,
    "userId": "626d1798-e390-452c-92eb-4324af221cfe"
  },
  {
    "email": "agarflowers@gmail.com",
    "firstName": "Yi Hsuan",
    "lastName": "Sung",
    "username": "FLORAGILD",
    "password": "password",
    "image": FLORAGILD,
    "userId": "626d13e5-e6a9-4689-9933-49ab2a490bae"
  },
  {
    "email": "franciscofelixonline@gmail.com",
    "firstName": "Francisco",
    "lastName": "FÉLIX",
    "username": "FRANCISCO-FÉLIX",
    "password": "password",
    "image": FRANCISCOFELIX,
    "userId": "6387a97a-9bbb-4533-b828-e41d3e04f9ea"
  },
]

export const usersWithImagesMobile = [
    {
      "email": "info@manmadeskins.com",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "ManMadeSkins",
      "password": "password",
      "image": MANMADESKINS,
      "userId": "626d172f-7e7a-48ad-b647-ae1a9ef75956"
    },
    {
      "email": "chenjiaying6688@gmail.com",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "JiayingChen",
      "password": "password",
      "image": JIAYINGCHEN,
      "userId": "627e9328-f8e4-4eaa-a67d-247ffd3c2dea"
    },
    {
      "email": "contactdesireescarborough@gmail.com",
      "firstName": "Desiree",
      "lastName": "Scarborough",
      "username": "DesireeScarborough",
      "password": "password",
      "image": DESIREESCARBOROUGH,
      "userId": "626d143a-8b90-449c-b9f1-cf2a14de693e"
    },
    {
      "email": "zaydlatib@gmail.com",
      "firstName": "Zayd",
      "lastName": "Latib",
      "username": "JawlNini",
      "password": "password",
      "image": ZAYD,
      "userId": "626d14f3-8419-45a8-bd08-a5a67429d86b"
    },
    {
      "email": "sorcerersourcing@gmail.com",
      "firstName": "sorcerer",
      "lastName": "_",
      "username": "sorcerer",
      "password": "password",
      "image": SORCERER,
      "userId": "62894662-8971-4249-bddd-739f6b134598"
    },
    {
      "email": "xu@studiobay.co.uk",
      "firstName": "XU",
      "lastName": "ZHANG",
      "username": "XUZHANG",
      "password": "password",
      "image": XUZHANG1,
      "userId": "62c9931a-301b-4848-959c-eb167c390b12"
    },
    {
      "email": "studio@ginagrnw.com",
      "firstName": "Gina",
      "lastName": "Grnw",
      "username": "GinaGrnw",
      "password": "password",
      "image": GINAGNRW1,
      "userId": "626d1628-1e77-4fb6-b31c-4055a5b6ae8f"
    },
    {
      "email": "ediop@ifm-paris.fr",
      "firstName": "Tareet",
      "lastName": "_",
      "username": "tareet",
      "password": "password",
      "image": TAREET,
      "userId": "6351ea6c-2024-4441-b63a-a50247e5d118"
    },
    {
      "email": "info@skndlss.xyz",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "SKNDLSS",
      "password": "password",
      "image": SKNDLSS2,
      "userId": "626d1709-000d-42e4-907e-d6c729a45f7b"
    },
    {
      "email": "annacompagnone@icloud.com",
      "firstName": "Anna",
      "lastName": "Compagnone",
      "username": "Annacompagnone",
      "password": "password",
      "image": ANNACOMPAGNONE,
      "userId": "62da1275-4fd2-44a7-829b-ad314a6d4465"
    },
    {
      "email": "h.chiayunhu@gmail.com",
      "firstName": "Chiayun",
      "lastName": "Hu",
      "username": "ChiayunHu",
      "password": "password",
      "image": CHIAYUNHU,
      "userId": "627aca62-03be-4f37-bc4e-7cedc7e6a843"
    },
    {
      "email": "info@skndlss.xyz",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "SKNDLSS",
      "password": "password",
      "image": SKNDLSS1,
      "userId": "626d1709-000d-42e4-907e-d6c729a45f7b"
    },
    {
      "email": "puerparasitus@yahoo.com",
      "firstName": "Puer",
      "lastName": "Parasitus",
      "username": "PuerParasitus",
      "password": "password",
      "image": PUERPARASITUS1,
      "userId": "626d10fc-1475-4690-a434-bb18ea5a142a"
    },
    {
      "email": "studio.sarahgarfield@gmail.com",
      "firstName": "Sarah",
      "lastName": "Garfield",
      "username": "Sarah Garfield_London",
      "password": "password",
      "image": SARAHGARFIELD,
      "userId": "62a0c3bd-6320-4494-a17b-7376cd73050a"
    },
    {
      "email": "rhycni@gmail.com",
      "firstName": "RHYCNI",
      "lastName": "RHYCNI",
      "username": "RHYCNI",
      "password": "password",
      "image": RHYCNI,
      "userId": "62efd4b9-b196-4ec7-ab38-dac2dd1df02e"
    },
    {
      "email": "xu@studiobay.co.uk",
      "firstName": "XU",
      "lastName": "ZHANG",
      "username": "XUZHANG",
      "password": "password",
      "image": XUZHANG,
      "userId": "62c9931a-301b-4848-959c-eb167c390b12"
    },
    {
      "email": "generalvi.world@gmail.com",
      "firstName": "Victoria",
      "lastName": "Generalova",
      "username": "generalvi",
      "password": "password",
      "image": VICTORIA,
      "userId": "647d856f-5016-423c-bd38-57cd2dc4d31b"
    },
    {
      "email": "zaydlatib@gmail.com",
      "firstName": "Zayd",
      "lastName": "Latib",
      "username": "JawlNini",
      "password": "password",
      "image": ZAYDLATIB2,
      "userId": "626d14f3-8419-45a8-bd08-a5a67429d86b"
    },
    {
      "email": "puerparasitus@yahoo.com",
      "firstName": "Puer",
      "lastName": "Parasitus",
      "username": "PuerParasitus",
      "password": "password",
      "image": PUERPARASITUS2,
      "userId": "626d10fc-1475-4690-a434-bb18ea5a142a"
    },
    {
      "email": "jessicammatier@gmail.com",
      "firstName": "Jessica",
      "lastName": "Matier",
      "username": "JessicaMatier",
      "password": "password",
      "image": JESSICAMATIER,
      "userId": "626d175a-f97e-46a1-8490-14f16a21b8ea"
    },
    {
      "email": "jessicammatier@gmail.com",
      "firstName": "Jessica",
      "lastName": "Matier",
      "username": "JessicaMatier",
      "password": "password",
      "image": GINA,
      "userId": "626d175a-f97e-46a1-8490-14f16a21b8ea"
    },
    {
      "email": "jessicammatier@gmail.com",
      "firstName": "Jessica",
      "lastName": "Matier",
      "username": "JessicaMatier",
      "password": "password",
      "image": EMBRYO_A_03_1,
      "userId": "626d175a-f97e-46a1-8490-14f16a21b8ea"
    },
    {
      "email": "yuxuanzhang@fitnyc.edu",
      "firstName": "Sample",
      "lastName": "Name",
      "username": "HeinrichZhang",
      "password": "password",
      "image": HEINRICHZHANG,
      "userId": "626d16da-9dc0-4888-ac58-d6d229abe206"
    },
    {
      "email": "michaelravidnal@gmail.com",
      "firstName": "Michael",
      "lastName": "Landivar",
      "username": "MichaelLandivar",
      "password": "password",
      "image": MICHAELLANDIVAR,
      "userId": "626d1798-e390-452c-92eb-4324af221cfe"
    },
    {
      "email": "agarflowers@gmail.com",
      "firstName": "Yi Hsuan",
      "lastName": "Sung",
      "username": "FLORAGILD",
      "password": "password",
      "image": FLORAGILD,
      "userId": "626d13e5-e6a9-4689-9933-49ab2a490bae"
    },
    {
      "email": "franciscofelixonline@gmail.com",
      "firstName": "Francisco",
      "lastName": "FÉLIX",
      "username": "FRANCISCO-FÉLIX",
      "password": "password",
      "image": FRANCISCOFELIX,
      "userId": "6387a97a-9bbb-4533-b828-e41d3e04f9ea"
    },
]