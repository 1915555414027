import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import logoImage from '../LandingPage/logo/EMBRYOLOGOblack.png'
import config from '../../config';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';

import {
  Button,
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import HomePageComponent from './HomePage';

import facebookImage from '../../assets/embryo_marketplace_resized.jpg';
import twitterImage from '../../assets/embryo_marketplace_resized.jpg';

import SectionHero from './SectionHero/SectionHero';
import SectionHowItWorks from './SectionHowItWorks/SectionHowItWorks';
import SectionFilteredSearches from './SectionFilteredSearches/SectionFilteredSearches';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  const [isLandingPageShowing, setIsLandingPageShowing] = useState(true);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
    document.querySelector("body").style.overflow = "hidden";
  }, []);

  const rerenderPage = () => {
    if (isLandingPageShowing && mounted) {
      document.querySelector("body").style.overflow = "auto";
      setIsLandingPageShowing(false);
      history.push('/home?meta_featured')
    }
  };

  const landingPage = () => {
    /* document.getElementsByTagName("body")[0].style.overflow = "hidden"; */
    return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <div className={css.landTopbar}>
          </div>
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
        <div className={css.LogoMenuLandingPage}>
       <NamedLink className={css.logoLinkLandingPage} name="LandingPage">
       <img src={logoImage} alt="Logo" className={css.logoLandingPage} />
         </NamedLink>
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionFilteredSearches />
              </div>
            </li>
          </ul>
          <div className={css.seeMoreonHomePage}>
            <Button onClick={rerenderPage}>
              <FormattedMessage id="LandingPage.seeMoreonHomePage" />
            </Button>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <div className={css.landFooter}>
          </div>
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
    );
  };

  const homePage = () => {
    return (
      <HomePageComponent/>
    );
  };

  return (
    isLandingPageShowing? landingPage() : homePage()
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  connect(mapStateToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
